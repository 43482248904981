<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-8">
      <div class="card">
        <div class="card-header">
          <h5>Usuarios</h5>
        </div>
        <div class="card-body">
          <b-table
            :striped="false"
            :small="true"
            :hover="true"
            :fields="['nome', 'tipo', 'acoes']"
            :items="lista"
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>

                <button
                  @click="modal_departamentos(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Departamentos"
                >
                  <i class="fas fa-archive text-primary"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista.length"
            :per-page="perPage"
            aria-controls="grade_curricular-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>

    <!-- Modal de departamentos do usuario -->

    <div>
      <b-modal v-model="modalShow" title="Departamentos">
        <b-form-group>
          <div class="row">
            <div class="col-10">
              <label class="col-md-12">Departamentos :*</label>
              <select class="form-control" v-model="departamento_selecionado">
                <option
                  v-for="(lista, index) in lista_departamento"
                  :key="index"
                  :value="lista"
                >
                  {{ lista.id }} - {{ lista.nome }}
                </option>
              </select>
            </div>
            <div class="col-2">
              <label class="col-md-12"></label>
              <div class="text-right w-100">
                <a
                  @click="Adicionar_departamento()"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Adicionar"
                >
                  <i class="fas fa-plus text-success"></i>
                </a>
              </div>
            </div>
          </div>
        </b-form-group>

        <div class="col-12">
          <b-table
            :striped="true"
            :small="true"
            :hover="true"
            :ligth="true"
            :fields="['nome', 'acoes']"
            :items="departamentos"
            :per-page="perPage"
            :current-page="currentPage"
            id="id-table"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #cell(acoes)="{ index }">
              <div class="text-right w-100">
                <button
                  @click="elimiar_departamento(index)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Eliminar"
                >
                  <i class="fas fa-times text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista.length"
            :per-page="perPage"
            aria-controls="grade_curricular-table"
          >
          </b-pagination>
        </div>

        <template #modal-footer="">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="success" @click="salvar()">
            Salvar
          </b-button>
          <b-button size="sm" variant="danger" @click="modalShow = false">
            Cancel
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import {fireAlert} from "@/core/mixins/alertMessageMixin";

export default {
  // mixins: [fireAlert],
  data() {
    return {
      modalShow: false,
      currentPage: 1,
      perPage: 50,
      departamentos: [],
      departamento_selecionado: "",

      form: {
        departamentos: [],
        id: "",
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usuarios" }]);
  },
  computed: {
    lista() {
      return this.$store.state.usuarios.lista;
    },

    lista_departamento() {
      return this.$store.state.departamentos.lista;
    },

    get_usuario() {
      return this.$store.state.usuarios.usuario;
    },
  },
  created() {
    this.Listar();
    this.$store.dispatch("departamentos/all");
  },
  methods: {
    async Listar() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("usuarios/all").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },

    atualizar(value) {
      this.$router.push({ name: "createGradeCurricular" });
      this.$store.dispatch("atualizar", value);
    },

    async modal_departamentos(value) {
      await this.Get_usuario(value);
      this.departamentos = [];
      this.departamento_selecionado = {};

      this.departamentos = this.$store.state.usuarios.usuario.departamentos;
      this.modalShow = true;
    },

    // Metodos secundarios

    async Get_usuario(value) {
      // this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("usuarios/get", value).finally(() => {
        // this.$store.dispatch("MudarPreloader", false);
      });
    },

    async elimiar_departamento(index) {
    this.departamentos.splice(index,1);
    },

    async Adicionar_departamento() {
      console.log(this.departamento_selecionado);
      if (this.departamento_selecionado.id >= 1) {
        var b = [0];
        let index;

        for (index in this.departamentos) {
          if (!b.includes(this.departamentos[index].id)) {
            b.push(this.departamentos[index].id);
          }
        }

        if (!b.includes(this.departamento_selecionado.id)) {
          this.departamentos.push(this.departamento_selecionado);
        }
      }
    },
    async salvar() {
      var index;
      for (index in this.departamentos) {
        if (!this.form.departamentos.includes(this.departamentos[index].id)) {
          this.form.departamentos.push(this.departamentos[index].id);
        }
      }

      this.form.id = this.$store.state.usuarios.usuario.id;
      // console.log(this.form)

      await this.create_departamento_atendente(this.form);
    },
    async create_departamento_atendente(value) {
      await this.$store.dispatch(
        "usuarios/create_departamento_atendente",
        value
      );
      // this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },

    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloquear este usuario?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("bloquear_finan_pedido_compra", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },

    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>